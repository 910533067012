var site = site || {};
var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};

(function ($) {
  const INVENTORY_STATUS = {
    TEMP_OOS: 2,
    COMING_SOON: 3,
    SOLD_OUT: 7
  };

  function handleInputVisibility(product, skuData) {
    if (!skuData) {
      return;
    }
    const invStatus = skuData.INVENTORY_STATUS;
    const drupalSettings = Drupal.settings.common || {};
    const isShoppable = skuData.isShoppable;
    const isNotifyMe = drupalSettings.enable_toos_cs_notify && 
    (invStatus === INVENTORY_STATUS.TEMP_OOS || invStatus === INVENTORY_STATUS.COMING_SOON);
    const isSoldOut = drupalSettings.enable_sold_out && invStatus === INVENTORY_STATUS.SOLD_OUT;

    if (isShoppable) {
      $(document).trigger('product.waitlist.reset', skuData);
    } else if (isNotifyMe || isSoldOut) {
      $(document).trigger('product.waitlist.init', skuData);
    }
  }

  $(document).on('inv_status_data:updated', '.js-product', function (e) {
    const $product = $(this);
    const $statusList = $('.js-inv-status-list', $product);
    const skuBaseId = $statusList.data('sku-base-id');
    const skuData = prodcat.data.getSku(skuBaseId);

    if (skuData) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData, $product);
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    const $product = $(this);
    const $statusList = $('.js-inv-status-list', $product);
    const skuData = prodcat.data.getSku(skuBaseId);

    if ($statusList.length < 1) {
      return null;
    }

    $statusList.data('sku-base-id', skuBaseId);

    if (skuData) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData, $product);
    }
  });

  $(document).on('product:init', '.js-product', function (e) {
    const $product = $(this);
    const $statusList = $('.js-inv-status-list', $product);
    const skuBaseId = $statusList.data('sku-base-id');
    const skuData = prodcat.data.getSku(skuBaseId);

    if (skuData) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData, $product);
    }
  });

  Drupal.behaviors.inventoryStatusV1 = {
    update: function ($statusList, skuData, $product) {
      if (!skuData?.INVENTORY_STATUS || skuData.SKU_ID !== 'SKU' + $statusList.data('sku-base-id')) {
        return null;
      }

      const skuBaseId = $statusList.data('sku-base-id');
      const invStatus = skuData.INVENTORY_STATUS;
      const $addButtons = $product.find('.js-add-to-cart');
      const $statusToShow = $('.js-inv-status-' + invStatus, $statusList);

      handleInputVisibility($product, skuData);
      $('li', $statusList).hide();

      $addButtons.each(function () {
        const $addBtn = $(this);
        const $addBtnCopy = $addBtn.find('.js-add-to-cart__copy');
        const $stickyCart = $('.js-sticky__cart__cta__link');
        const btnLabels = $addBtn.data('inv-status-labels');
        const defaultLabel = $addBtn.data('default-label');
        const params = {
          skuBaseId: skuBaseId,
          isShoppable: skuData?.isShoppable,
          status: invStatus,
          label: $addBtn.filter(':visible').data('default-label')
        };

        $addBtn.removeClass('button--disabled').data('disabled', false);

        if ($addBtnCopy.length) {
          $addBtnCopy.text(defaultLabel);
        } else {
          $addBtn.text(defaultLabel);
        }

        if ($stickyCart.length) {
          site.stickyButton.update(params);
        }

        if (btnLabels?.invStatus && btnLabels[invStatus]) {
          const newLabel = btnLabels[invStatus];

          if ($addBtnCopy.length) {
            $addBtnCopy.text(newLabel);
          } else {
            $addBtn.text(newLabel);
          }
          params.label = newLabel;
        }

        if ($statusToShow.length && $statusToShow.data('display')) {
          $statusToShow.show();
        }
      });

      $statusList.trigger('inv_status_display:updated');
    }
  };
})(jQuery);